import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

// This data is ONLY FOR TESTING
const states = [
 {
  name: 'Delaware',
  imageSrc: 'state-logo-delaware.png',
  stateResourcesURL: 'https://dhss.delaware.gov/dhss/dph/hsp/medmarhome.html',
  isCertificated: true,
  providers: [
   {
    name: 'CMed',
    imageSrc: 'cmed.png',
    newPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=DE&timezone=EST&skip=yes&visit_type_id=2427&utm_source=bennabispilot',
    renewingPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=DE&timezone=EST&skip=yes&visit_type_id=2428&utm_source=bennabispilot',
   },
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/bennabis',
    renewingPatientURL: 'https://leafwell.com/bennabis',
   }
  ]
 },
 {
  name: 'Maine',
  imageSrc: 'state-logo-maine.png',
  stateResourcesURL: 'https://www.maine.gov/dafs/ocp/medical-use/applications-forms/registryidentificationcard-instructions',
  isCertificated: true,
  providers: [
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/medical-card/maine?utm_source=bennabis',
    renewingPatientURL: 'https://leafwell.com/medical-card/maine?utm_source=bennabis',
   }
  ]
 },
 {
  name: 'Maryland',
  imageSrc: 'state-logo-maryland.png',
  stateResourcesURL: 'https://cannabis.maryland.gov/Pages/Medical_Cannabis.aspx',
  isCertificated: true,
  providers: [
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/medical-card/maryland?utm_source=bennabis',
    renewingPatientURL: 'https://leafwell.com/medical-card/maryland?utm_source=bennabis',
   }
  ]
 },
 {
  name: 'Massachusetts',
  imageSrc: 'state-logo-massachusetts.png',
  stateResourcesURL: 'https://masscannabiscontrol.com/',
  isCertificated: true,
  providers: [
   {
    name: 'CMed',
    imageSrc: 'cmed.png',
    newPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=MA&timezone=EST&skip=yes&visit_type_id=2431&utm_source=bennabispilot',
    renewingPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=MA&timezone=EST&skip=yes&visit_type_id=2432&utm_source=bennabispilot',
   },
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/bennabis',
    renewingPatientURL: 'https://leafwell.com/bennabis',
   }
  ]
 },
 {
  name: 'New Jersey',
  imageSrc: 'state-logo-new-jersey.png',
  stateResourcesURL: 'https://www.nj.gov/cannabis/medicinalcannabis/',
  isCertificated: true,
  providers: [
   {
    name: 'CMed',
    imageSrc: 'cmed.png',
    newPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=NJ&timezone=EST&skip=yes&visit_type_id=1690',
    renewingPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=NJ&timezone=EST&skip=yes&visit_type_id=1691',
   },
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/bennabis',
    renewingPatientURL: 'https://leafwell.com/bennabis',
   }
  ]
 },
 {
  name: 'New Mexico',
  imageSrc: 'state-logo-new-mexico.png',
  stateResourcesURL: 'https://www.nmhealth.org/about/mcp/svcs/',
  isCertificated: true,
  providers: [
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/bennabis',
    renewingPatientURL: 'https://leafwell.com/bennabis',
   }
  ]
 },
 {
  name: 'New York',
  imageSrc: 'state-logo-new-york.png',
  stateResourcesURL: 'https://cannabis.ny.gov/patients',
  isCertificated: true,
  providers: [
   {
    name: 'CMed',
    imageSrc: 'cmed.png',
    newPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=NY&timezone=EST&skip=yes&visit_type_id=2429&utm_source=bennabispilot',
    renewingPatientURL:
     'https://cannacaredocs.videovisitmd.com/f/vshop-schedule?state=NY&timezone=EST&skip=yes&visit_type_id=2430',
   },
   {
    name: 'Leafwell',
    imageSrc: 'leafwell.png',
    newPatientURL: 'https://leafwell.com/bennabis',
    renewingPatientURL: 'https://leafwell.com/bennabis',
   }
  ]
 },
]

const MembershipCard = () => {
 const [expandedState, setExpandedState] = useState(null)

 const toggleAccordion = (index) => {
  setExpandedState(expandedState === index ? null : index)
 }

 const isMobile = window.innerWidth <= 767

 return (
  <React.Fragment>
   <Helmet>
    <title>
     Find a Physician | Bennabis Health: Medical Cannabis Discounts & Education
    </title>
    <meta
     name="description"
     content="New Jersey residents can find a provider, here, through the New Jersey Cannabis Regulatory Commission"
    />
   </Helmet>
   <div className="sectionWrap physicianTemplate">
    <img
     src={require('../assets/images/graphic-01.png')}
     alt=""
     className="backgroundSource"
    />
    <div className="sectionContainer" style={{ minHeight: '500px' }}>
     <div>
      <div
       style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '50px',
       }}
      >
       <span style={{ fontSize: '1.25rem', fontWeight: '500' }}>
        Speak with a medical cannabis expert today!
       </span>
       <p>
        We have secured a discount on medical evaluations for your medical
        cannabis card through our affiliates.
       </p>
       <div
        className="graphic"
        style={{
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'center',
         alignItems: 'center',
         padding: '0 25px',
        }}
       >
        <img
         src={require('../assets/images/affiliates/cmed.png')}
         alt=""
         style={{ width: '50%' }}
        />
        <img
         src={require('../assets/images/affiliates/leafwell.png')}
         alt=""
         style={{ width: '50%' }}
        />
       </div>
      </div>
      <div>
       {states.map((state, index) => (
        <div key={index}>
         <div style={{ padding: isMobile ? '0 16px' : '0' }}>
          <div
           style={{
            backgroundColor: '#dddcdb',
            height: '1px',
            width: '100%',
           }}
          />
          <div
           className="stateListButton"
           onClick={() => toggleAccordion(index)}
           style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#0A670AFF',
            fontWeight: '500',
            fontSize: '1rem',
            cursor: 'pointer',
           }}
          >
           <span>{state.name}</span>
           {expandedState === index ? <div>-</div> : <div>+</div>}
          </div>
         </div>
         {expandedState === index &&
          (state.isCertificated === false ? (
           <div style={{ paddingBottom: '25px' }}>
            <div className="stateListContent">
             <div
              style={{
               display: 'grid',
               gridTemplateColumns: isMobile
                ? 'repeat(1, 1fr)'
                : 'repeat(2, 1fr)',
               rowGap: '25px',
               width: '100%',
               padding: isMobile ? '20px 70px' : '25px 32px',
              }}
             >
              <div>
               <div
                style={{
                 textAlign: 'center',
                 fontSize: '1.25rem',
                 fontWeight: '600',
                }}
               >
                We are working to secure a certification partner, please check
                back.
               </div>
              </div>
              <div style={{ justifySelf: isMobile ? 'center' : 'end' }}>
               <a
                rel="noreferrer"
                target="_blank"
                href={state.stateResourcesURL}
               >
                <i className="fa fa-angle-right" aria-hidden="true"></i> State
                Resources
               </a>
              </div>
              <div style={{ gridRow: 2 }}></div>
              <div
               style={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
               }}
              >
               <img
                src={require(`../assets/images/${state.imageSrc}`)}
                alt=""
                style={{
                 width: isMobile ? '50%' : '40%',
                }}
               />
              </div>
             </div>
            </div>
           </div>
          ) : (
           <div style={{ paddingBottom: '25px' }}>
            <div className="stateListContent">
             <div
              style={{
               display: 'grid',
               gridTemplateColumns: isMobile
                ? 'repeat(1, 1fr)'
                : 'repeat(2, 1fr)',
               rowGap: '25px',
               width: '100%',
               padding: isMobile ? '20px 70px' : '25px 32px',
              }}
             >
              {state.providers.map((provider, index) => (
               <div
                key={index}
                style={{
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: isMobile ? 'center' : 'space-between',
                 alignItems: 'center',
                 padding: '10px 0',
                }}
               >
                <img src={require(`../assets/images/affiliates/${provider.imageSrc}`)} alt="" />
                <div
                 style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '20px 0',
                 }}
                >
                 <a
                  rel="noreferrer"
                  target="_blank"
                  href={provider.newPatientURL}
                  style={{
                   fontSize: '1rem',
                  }}
                 >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  New Patient
                 </a>
                 <a
                  rel="noreferrer"
                  target="_blank"
                  href={provider.renewingPatientURL}
                  style={{
                   fontSize: '1rem',
                   marginLeft: '20px',
                  }}
                 >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                  Renewing Patient
                 </a>
                </div>
               </div>
              ))}
              <div
               style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0',
               }}
              >
               <img
                src={require(`../assets/images/${state.imageSrc}`)}
                alt=""
                style={{
                 width: isMobile ? '50%' : '40%',
                }}
               />
               <div style={{
                padding: '30px 0',
               }}>
                <a
                 rel="noreferrer"
                 target="_blank"
                 href={state.stateResourcesURL}
                >
                 <i className="fa fa-angle-right" aria-hidden="true"></i> State
                 Resources
                </a>
               </div>
              </div>
             </div>
            </div>
           </div>
          ))}
        </div>
       ))}
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default MembershipCard
