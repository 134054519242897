import React from 'react'
import { Helmet } from 'react-helmet'

const Enroll = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Membership Plan</title>{' '}
   </Helmet>
   <div
    className="sectionWrap enrollmentPlans"
    style={{
     backgroundImage: `url(${process.env.PUBLIC_URL + 'graphic-01.png'})`,
    }}
   >
    <div className="sectionContainer">
     <div className="plansWrap">
      <div className="singIn" style={{ marginLeft: 0 }}>
       <a
        rel="noreferrer"
        href={process.env.REACT_APP_MEMBERS_APP}
        target="_blank"
       >
        Already A Member ? Sign In
       </a>
      </div>
      <div className="linkSignIn">
       <p>
        If you enrolled through group benefits,{' '}
        <a
         href={process.env.REACT_APP_HEALTH_PLAN_ENROLL_CONFIRM}
         target="_blank"
         rel="noreferrer"
        >
         Activate your account now.
        </a>
       </p>
      </div>
      <h5 style={{
       fontSize: '2.5em',
       color: '#082F58',
       textAlign: 'center',
      }}>
       Save on your medical cannabis purchases!
      </h5>
      <div style={{
       backgroundColor: 'rgba(233, 252, 225, 1)',
       padding: '20px',
       marginBottom: '20px',
      }}>
       <ul
        className={'green-arrow-list'}
        style={{
         margin: 0,
         fontSize: '1.25em',
        }}
       >
        <li>
         15% product discount at Network Dispensaries*
        </li>
        <li>
         Discounts on certifications for medical cannabis cards
        </li>
        <li>
         Online medical cannabis resources
        </li>
       </ul>
      </div>
      <h6
       style={{
        marginBottom: 0,
       }}
      >
        <span
         style={{
          fontWeight: 'bold',
         }}
        >
         Premium Membership:
        </span>
       <span
        style={{
         fontSize: '1.5em',
         paddingLeft: '10px',
        }}
       >
         $98
        </span>
      </h6>
      <span>
        Annual Subscription
       </span>
      <div className="singIn" style={{ marginLeft: 0 }}>
       <a
        href="/enroll/premium-plan"
       >
        ENROLL NOW
       </a>
      </div>
      <p>
       Currently available in DE, ME, MD, MA, NJ, NM, NY and DC.
       <br />
       State Medical Cannabis Card Required for Premium Membership.
       <br />
       Don’t have a card?
       {' '}
       <a
        href="/membership-card"
        style={{
         color: "#082F58",
         fontWeight: 600,
         textDecoration: 'underline',
        }}
       >
        Let us help you get one.
       </a>
      </p>
      <div className="linkSignIn" style={{ marginTop: '15px' }}>
       <p>
        If you enrolled through group benefits,{' '}
        <a
         href={process.env.REACT_APP_HEALTH_PLAN_ENROLL_CONFIRM}
         target="_blank"
         rel="noreferrer"
        >
         Activate your account now.
        </a>
       </p>
      </div>
      <p>
       * Discount may vary by Network Dispensary.
       Taxes may apply at Recreational/Adult Use Network Dispensaries.
      </p>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Enroll
